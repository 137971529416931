import { useEffect, useState } from 'react';
import { Container, Group, Burger, Image, ActionIcon, rem, TextInput, CloseButton, Box, Text, Drawer, Transition, Stack } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import classes from './../../styles/HeaderSimple.module.css';
import { IconBrandFacebook, IconBrandInstagram, IconBrandTiktok, IconBrandYoutube, IconSearch } from '@tabler/icons-react';
import classesFooter from './../../styles/Footer.module.css';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Cookies from 'universal-cookie';
import axios from 'axios';
import useStore from '../../store/useStore';
const cookies = new Cookies(null, { path: '/' });


export function HeaderSimple() {
    let location = useLocation();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [opened, { toggle, close }] = useDisclosure();
    const [openedSearch, setOpenedSearch] = useState(false);
    const matches = useMediaQuery('(max-width: 36em)');
    let [topBar, setTopBar] = useState<any>(null);
    let [allCategories, setAllCategories] = useState<any[]>([]);
    const dataStore = useStore((state: any) => state.store);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/category`)
        .then(({data}) => {
            setAllCategories(data)
        })
        .catch((error) => console.log(error));
    }, [])
    
    return (
        <>
            <header className={classes.header} style={{minHeight: 80, paddingTop: dataStore?.topBar && dataStore?.topBar?.show && dataStore?.topBar?.content ? 0 : 10 }} >
                {dataStore?.topBar && dataStore?.topBar?.show && dataStore?.topBar?.content
                    ? <Box bg={dataStore?.topBar?.background} h={35} mb={10} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Text size='md' ta={'center'} fw={"bold"}  c={dataStore?.topBar?.color}>{dataStore?.topBar?.content}</Text>
                    </Box>
                    : null
                }
            
                <Container size="xl" h={60} display={"flex"} style={{justifyContent: "space-between", alignItems: "center", gap: 10}} >
                    <Group w={"100%"} justify='space-between' align='center' gap={0} >
                        <Burger size="md" opened={opened} onClick={toggle} color='dark' hiddenFrom="sm" aria-label="Toggle navigation" />
                        <Image src={dataStore?.logo && dataStore?.logo !== "" ? `${process.env.REACT_APP_API_URL_IMAGES}/${dataStore?.logo}.webp` : "/Bi3li_logo.png"} alt='logo' h={matches ? 45 : 55} w={"100%"} style={{cursor: "pointer"}} onClick={() => navigate("/")} />
                        {/* <Image src={"/logo.png"} h={matches ? 45 : 55} w={"100%"} style={{cursor: "pointer"}} onClick={() => navigate("/")} /> */}
                        <Group h={"100%"} align='center' justify='center' gap={20} visibleFrom='sm'>
                            <Text data-active={location.pathname === "/" || undefined} className={classes.link} component={Link} to={`/`} >{"الرئيسية"}</Text>
                            <Text data-active={location.pathname === "/shipping-and-handling" || undefined} className={classes.link} component={Link} to={`/shipping-and-handling`} >{"الشحن والتسليم"}</Text>
                            <Text data-active={location.pathname === "/payment-methods" || undefined} className={classes.link} component={Link} to={`/payment-methods`} >{"طرق الدفع"}</Text>
                            <Text data-active={location.pathname === "/contact-us" || undefined} className={classes.link} component={Link} to={`/contact-us`} >{"اتصل بنا"}</Text>
                        </Group>
                        <ActionIcon size="md" color="dark" variant="transparent" radius={20} onClick={() => setOpenedSearch(true)} >
                            <IconSearch size={20} stroke={1.5} />
                        </ActionIcon>
                    </Group>
                </Container>
            </header>
            
            
        
            <Transition
                mounted={opened}
                transition="fade-down"
                duration={300}
                timingFunction="ease"
            >
                {(styles) => (
                    <Box bg={'#fff'} w={"100%"} pos={'fixed'} top={0} right={0} left={0} h={"auto"} style={{zIndex: 101, ...styles}}>
                        <Group w={"100%"} justify='space-between' align='center' my={20} px={20}>
                            <Image src={dataStore?.logo && dataStore?.logo !== "" ? `${process.env.REACT_APP_API_URL_IMAGES}/${dataStore?.logo}.webp` : "/logo.png"} alt='logo' h={45} w={"100%"} style={{cursor: "pointer"}} onClick={() => navigate("/")} />
                            <CloseButton onClick={() => close()} />
                        </Group>
                        <Stack h={"100%"} align='flex-start' justify='flex-start' gap={0}>
                            <Text data-active={location.pathname === "/" || undefined} className={classes.linkSmall} component={Link} to={`/`} >{"الرئيسية"}</Text>
                            <Text data-active={location.pathname === "/shipping-and-handling" || undefined} className={classes.linkSmall} component={Link} to={`/shipping-and-handling`} >{"الشحن والتسليم"}</Text>
                            <Text data-active={location.pathname === "/payment-methods" || undefined} className={classes.linkSmall} component={Link} to={`/payment-methods`} >{"طرق الدفع"}</Text>
                            <Text data-active={location.pathname === "/contact-us" || undefined} className={classes.linkSmall} component={Link} to={`/contact-us`} >{"اتصل بنا"}</Text>
                        </Stack>
                    </Box>
                )}
            </Transition>
            

            <Transition
                mounted={openedSearch}
                transition="fade-down"
                duration={300}
                timingFunction="ease"
            >
                {(styles) => (
                    <Box bg={'#fff'} w={"100%"} pos={'fixed'} top={0} right={0} left={0} h={80} style={{zIndex: 100, ...styles}}>
                        <Container size={'lg'} h={"100%"} display={'flex'} style={{justifyContent: "space-between", alignItems: "center", gap: 20}}>
                            <TextInput
                                variant="filled" radius="xs" placeholder="اسم المنتج"
                                value={searchParams.get("search") || ""} w={"100%"}
                                onChange={(event) => setSearchParams({search: event.currentTarget.value})}
                                leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} />}
                                rightSection={<CloseButton aria-label="Clear input" onClick={() => setSearchParams()} style={{ display: searchParams.get("search") ? undefined : 'none' }} />}
                                className={classes.inputSearch}
                                styles={{
                                    input: {height: 50}
                                }}
                            />
                            <CloseButton onClick={() => setOpenedSearch(false)} />
                        </Container>
                    </Box>
                )}
            </Transition>
        </>
    );
}