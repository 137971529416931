import { Text, Container, Group, Image, useMantineTheme, Title, SimpleGrid, Box, ActionIcon, rem, Card, Overlay, Button, Grid } from '@mantine/core';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import axios from 'axios';
import classes from './../../styles/CategoriesSection.module.css';
import useStore from '../../store/useStore';
import { Link } from 'react-router-dom';

interface Props {
    background: string;
    title: string;
    isFirst: boolean
}

export function CategoryCard({background, title, isFirst}: Props) {
    const matches = useMediaQuery('(max-width: 36em)');
    const dataStore = useStore((state: any) => state.store);

    return (
        <Card 
            radius="xs" className={classes.card} 
            pb={isFirst ? "56.25%" : "54.69462%"}
            style={{backgroundImage: `url("${process.env.REACT_APP_API_URL_IMAGES}/${background}.webp")`}}
        >
            <Overlay bg={"#2b2c2c66"} zIndex={0} />

            <Group justify='flex-end' align='flex-start' className={classes.contentCard}>
                <Text size={matches ? "md" : "xl"} fw={700} c={"#fff"} className={classes.titleCard}>
                    {title}
                </Text>

                <Button 
                    radius={0} variant="filled" size={matches ? "md" : "xl"}
                    color={dataStore?.information?.backgroundColor || "#48a172"}
                    c={dataStore?.information?.textColor || "#fff"}
                    component={Link} to={`/products/${title}`} 
                >
                    تسوق الان
                </Button>
            </Group>
        </Card>
    );
}