import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import { Box, Container, Group, Image, List, Text, Title } from '@mantine/core';

export function PaymentMethods () {
    return (
        <>
            <HeaderSimple />
            <Container size={"sm"} mt={100}>
                <Group align='center' justify='center' w={"100%"}>
                    <Image src={"/cod.png"} alt='cod' w={"100%"} />
                </Group>
                <Group align='flex-start' justify='center' w={"100%"} opacity={0.9} style={{flexDirection: "column"}}>
                    <Text>مرحبًا بكم في متجرنا الإلكتروني! نحن نقدم لكم طريقة سهلة وآمنة للدفع عند التسوق معنا.</Text>
                    <Text>الدفع عند الاستلام استمتعوا بتجربة تسوق مريحة مع خيار الدفع عند الاستلام. ببساطة، قموا بإتمام طلبكم عبر موقعنا، وعند استلامكم للمنتجات، يمكنكم الدفع نقدًا لمندوب التوصيل.</Text>
                    <Text>كيفية الاستفادة من خيار الدفع عند الاستلام:</Text>
                    <List size="sm">
                        <List.Item>اختر المنتجات التي ترغب في شرائها وأضفها إلى سلة التسوق.</List.Item>
                        <List.Item>انتقل إلى صفحة الدفع وأدخل تفاصيل الشحن.</List.Item>
                        <List.Item>اختر "الدفع عند الاستلام" كطريقة الدفع.</List.Item>
                        <List.Item>أكد الطلب وانتظر وصول منتجاتك إلى باب منزلك.</List.Item>
                        <List.Item>عند استلامك الطلب، ادفع نقدًا لمندوب التوصيل.</List.Item>
                    </List>
                    <Text>لماذا الدفع عند الاستلام؟</Text>
                    <List size="sm">
                        <List.Item>راحة وأمان: لا حاجة لاستخدام بطاقات الائتمان أو المعلومات البنكية.</List.Item>
                        <List.Item>سهولة: ادفع فقط عندما تستلم المنتجات.</List.Item>
                        <List.Item>مرونة: مثالية لمن يفضلون الدفع النقدي.</List.Item>
                    </List>
                    <Text>نحن نسعى لتقديم أفضل خدمة لعملائنا ونجعل تجربة التسوق عبر الإنترنت بسيطة ومريحة. شكرًا لاختياركم متجرنا!</Text>
                </Group>
            </Container>
            <Footer/>
        </>
    );
}