import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import { Box, Container, Group, Image, Text, Title } from '@mantine/core';
import useStore from '../store/useStore';

export function ContactUs () {
    const dataStore = useStore((state: any) => state.store);
    return (
        <>
            <HeaderSimple />
            <Container size={"sm"} mt={100}>
                <Group align='center' justify='center' w={"100%"}>
                    <Image src={"/contact-us.png"} alt='contact-us' w={"100%"} />
                </Group>
                <Group align='flex-start' justify='center' w={"100%"} opacity={0.9} style={{flexDirection: "column"}}>
                    <Text>مرحبًا بكم في متجرنا الإلكتروني! نحن هنا لخدمتكم ونرحب بجميع استفساراتكم وملاحظاتكم. يمكنكم التواصل معنا من خلال الطرق التالية:</Text>
                    
                    {dataStore?.information?.phone && dataStore?.information?.phone != ""
                        ? <Text>
                            رقم الهاتف:
                            <Text span fw={'bold'}> {dataStore?.information?.phone}</Text>
                        </Text>
                        : null
                    }
                    {dataStore?.information?.email && dataStore?.information?.email != ""
                        ? <Text>
                            البريد الإلكتروني:
                            <Text span fw={'bold'}> {dataStore?.information?.email} </Text>
                        </Text>
                        : null
                    }
                    {dataStore?.information?.address && dataStore?.information?.address != ""
                        ? <Text>
                            العنوان:
                            <Text span fw={'bold'}> {dataStore?.information?.address}</Text>
                        </Text>
                        : null
                    }
                    
                    <Text>
                        ساعات العمل:
                        <Text span fw={'bold'}> من الاثنين إلى الجمعة: 9:00 صباحًا - 6:00 مساءً / السبت: 10:00 صباحًا - 4:00 مساءً / الأحد: مغلق</Text>
                    </Text>

                    <Text>شكرًا لكم لاختياركم متجرنا. نتطلع لخدمتكم وتلبية جميع احتياجاتكم.</Text>
                </Group>
            </Container>
            <Footer/>
        </>
    );
}